.red-table-row {
    background-color: #F7A9A7;
}

.green-table-row {
    background-color: #A5D7A7;
}

.asn-sent-table-row {
    text-decoration: line-through;
    background-color: #A5D7A7;
    color: green;
}

.cancelled-row {
    position: relative;
}

.cancelled-row::before {
    content: ''; 
    position: absolute;
    top: 50%; 
    left: 20px;
    width: calc(970px); 
    height: 1px; 
    background: red; 
    z-index: 1;
}

.cancelled-row .MuiDataGrid-cell:last-child {
    position: relative;
    z-index: 2; 
}
